import './bootstrap'
import '../css/app.css'

import { createSSRApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy'
import { createPinia } from 'pinia'

const pinia = createPinia();

const appName = import.meta.env.VITE_APP_NAME || 'Pitpot';

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
	setup({el, App, props, plugin})
	{
		// Set the guest ID
		if (!props.initialPage.props.auth.user) {
			const guestId = localStorage.getItem('guestId') ?? props.initialPage.props.guestId ?? '';
			if( guestId) {
				localStorage.setItem('guestId', guestId);
				props.initialPage.props.guestId = guestId;
				axios.defaults.headers.common['X-Guest-ID'] = guestId;
			}
		}

		return createSSRApp({render: () => h(App, props)})
			.use(plugin)
			.use(ZiggyVue)
			.use(pinia)
			.mount(el);
	},
	progress: {
		color: '#991b1b',
	},
});

function setGuestId(guestId)
{
	console.log('Given ID: ' + guestId);
	if ( localStorage.getItem('guestId') !== null ) {
		console.log('Guest ID already set: ' + localStorage.getItem('guestId'));
		guestId = localStorage.getItem('guestId');
	} else {
		console.log('Setting local storage guest ID: ' + guestId);
		localStorage.setItem('guestId', guestId)
	}
	axios.defaults.headers.common['X-Guest-ID'] = guestId;
}
