import axios from 'axios'
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */
import './echo'


/**
 * Google Analytics
 */
import {gtag, install} from 'ga-gtag'

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


install(import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID);
gtag('set', {
	'js': new Date(),
	'country': 'IN',
});
